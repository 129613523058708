import React from "react";
import "./LoadMediaSurvey.scss";

const LoadingAnimation = (props: Props) => {
    return (
        <div className={"loadMediaSurveyWrapper"}>
            <div className={"loadMediaSurvey"}>
                <div className={"lmsTitle"}>
                    Bist du im WLAN?
                </div>
                <div className={"lmsDescription"}>
                    Dieser Bereich lädt alle Fotos und Videos über das Internet.<br/>Wir empfehlen, dies nur im WLAN zu nutzen, da sonst das mobile Datenvolumen schnell aufgebraucht werden kann.
                </div>
                <div className={"lmsButtonWrapper"}>
                    <button className={"lmsLoadButton"} onClick={props.loadAction ? props.loadAction : null}>
                        Bilder und Videos laden
                    </button>
                    <button className={"lmsCancel"}  onClick={props.cancelAction ? props.cancelAction : null}>
                        Abbrechen
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoadingAnimation;