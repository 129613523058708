import React, {useEffect, useState} from "react";
import "./Answers.scss";
import Navigation from "../navigation/Navigation";
import authRequired from "../login/authRequired";
import adminRequired from "../login/adminRequired";
import axios from "axios";
import {API_COMPARE_TOKEN, BASEURL} from "../App";

export const AnswerTypes = [
    { key: "YES", label: "Ja" },
    { key: "NO", label: "Nein" },
    { key: "MAYBE", label: "Vielleicht" },
    { key: "UNKNOWN", label: "Unbekannt" }
];

export const getAnswerTypeOptions = () => {
    return (
        <>
            ${AnswerTypes.map(answerType => (
                <option key={`${answerType.key}_${answerType.label}`} value={answerType.key}>{answerType.label}</option>
            ))}
        </>
    );
}

const Answers = () => {
    const [allInvitations, setAllInvitations] = useState([]);

    useEffect(() => {
        document.title = "Antworten";
        getAllInvitations().then(response => setAllInvitations(response));
    }, []);

    const getAllInvitations = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/invitation/getAllInvitations?apiCompareToken=${API_COMPARE_TOKEN}`);
            return response.data;
        } catch (error) {
            console.error("Could not load all invitations:", error);
            return null;
        }
    }

    const getInvitationByInvitationId = (invitationId) => {
        return allInvitations.find(invitation => invitation.invitationId === invitationId) || null;
    }

    const getInvitationsByAnswer = (answer) => {
        return allInvitations.filter(invitation => invitation.answer === answer);
    }

    const renderInvitations = (invitations) => {
        return (
            <ul className="invitation-list">
                {invitations.map(invitation => {
                    const partOfInvitation = invitation.partOf ? getInvitationByInvitationId(invitation.partOf) : null;
                    return (
                        <li key={invitation.invitationId} className={`invitation ${invitation.child ? "isChild" : ""}`}>
                            <div id="fullName">{invitation.firstName} {invitation.familyName} {invitation.child && "(Kind)"}</div>
                            {partOfInvitation && (
                                <span>Begleitung von {partOfInvitation.firstName} {partOfInvitation.familyName}</span>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }

    const renderInvitationGroups = () => {
        return AnswerTypes.map(answerType => {
            const invitations = getInvitationsByAnswer(answerType.key);
            return (
                <div className={"invitationGroup"} key={answerType.key}>
                    <h2>{answerType.label} (Insgesamt: {invitations.length})</h2>
                    {renderInvitations(invitations)}
                </div>
            );
        });
    };

    return (
        <div className={"answersComponent"}>
            <div className={"answers-container"}>
                <Navigation/>
                <div className={"scroll-container"}>
                    <h1>Einladungsantworten</h1>
                    <h3>Gesamtanzahl an Einladungen: {allInvitations.length}</h3>
                    <div className={"answers-flex-container"}>
                        {renderInvitationGroups()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default authRequired(adminRequired(Answers, true));