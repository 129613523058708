import React from "react";
import "./Modal.scss";

const Modal = (props: Props) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <p>{props.infoText}</p>
                {
                    props.yesText ? (
                        <button onClick={props.yesAction ? props.yesAction : null}>{props.yesText}</button>
                    ) : (<></>)
                }
                {
                    props.noText ? (
                        <button onClick={props.noAction ? props.noAction : null}>{props.noText}</button>
                    ) : (<></>)
                }
            </div>
        </div>
    );
}

export default Modal;