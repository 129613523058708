import React, {useEffect} from "react";
import "./Home.scss";
import authRequired from "../login/authRequired";
import Navigation from "../navigation/Navigation";
import {Link} from "react-router-dom";
import CustomCountdown from "../shared/customCountdown/CustomCountdown";
import moment from "moment-timezone";
import {SUMMER_TIME} from "../App";

const Home = () => {
    const date = moment.tz("2024-08-10 15:00:00", "Europe/Berlin");
    const offset = (SUMMER_TIME ? 0 : 1) * 60 * 60000;

    useEffect(() => {
        document.title = "Startseite";
    }, []);

    return (
        <div className={"homeComponent"}>
            <div className={"home-container"}>
                <Navigation/>
                <div className={"scroll-container"}>
                    <div className={"weddingDate"}>
                        10. August 2024&nbsp;-&nbsp;<Link target={"_blank"}
                                                          to={"https://maps.app.goo.gl/G2USvsqMtG98Kkj4A"}>Overhagener
                        Weg 28, 59597 Erwitte</Link>
                    </div>
                    <div className={"centeredContainer"}>
                        {/* 1723294800000 */}
                        <CustomCountdown date={date.valueOf() - offset}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default authRequired(Home);