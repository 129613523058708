import React, {Component, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {loginCheck} from "./Login";

const authRequired = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        const [isLoggedIn, setIsLoggedIn] = useState(false);

        useEffect(() => {
            const invitationId = localStorage.getItem("invitationId");
            loginCheck(invitationId).then(result => {
                setIsLoggedIn(result);
                if(!result) {
                    navigate("/login");
                }
            });
        }, []);

        return isLoggedIn ? <Component {...props}/> : null;
    }
}

export default authRequired;