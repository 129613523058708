import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.scss";
import {BASEURL} from "../App";

const checkInvitation = async (invitationId, endpoint) => {
    try {
        const response = await axios.get(`${BASEURL}/api/invitation/${endpoint}?invitationId=${invitationId}`);
        return response.status === 200;
    } catch (error) {
        return false;
    }
}

export const adminCheck = async (invitationId) => {
    return await checkInvitation(invitationId, "isInvitationKeyAdmin");
}

export const loginCheck = async (invitationId) => {
    return await checkInvitation(invitationId, "isInvitationKeyValid");
}

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        invitationId: "",
        errorMessage: ""
    });

    useEffect(() => {
        document.title = "Anmeldung";
        const invitationId = localStorage.getItem("invitationId");
        if(invitationId) {
            loginCheck(invitationId).then(result => {
                if(result) {
                    navigate("/");
                }
            });
        }
    }, [navigate]);

    const handleLogin = async () => {
        const { invitationId } = formData;
        try {
            const loginValid = await loginCheck(invitationId.toUpperCase());
            if (loginValid) {
                localStorage.setItem('invitationId', invitationId.toUpperCase());
                navigate('/');
            } else {
                setFormData({ ...formData, errorMessage: "Der eingegebene Einladungscode ist nicht korrekt."});
            }
        } catch (error) {
            console.error('Fehler beim Anmelden:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, invitationId: e.target.value, errorMessage: "" });
    };

    return (
        <div className={"loginComponent"}>
            <div className={"backgroundImageWrapper"}>
                <div className={"overlay"}></div>
                <div className={"loginWrapper"}>
                    <h1>Anmeldung</h1>
                    <p>Bitte gebt Euren persönlichen Einladungscode ein, um euch anzumelden.</p>
                    <div className={"inputWrapper"}>
                        <input
                            type={"text"}
                            id={"password"}
                            value={formData.invitationId}
                            onChange={handleChange}
                            placeholder={"Einladungscode"}
                            className={formData.errorMessage ? "loginInput error" : "loginInput"}
                        />
                        {formData.errorMessage && <p className={"errorMessage"}>{formData.errorMessage}</p>}
                        <button onClick={handleLogin}>Anmelden</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;