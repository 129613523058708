import React, {useEffect, useState} from "react";
import "./Food.scss";
import Navigation from "../navigation/Navigation";
import authRequired from "../login/authRequired";
import FoodManagement from "./FoodManagement";
import axios from "axios";
import {API_COMPARE_TOKEN, BASEURL} from "../App";
import CustomCheckbox from "../shared/customCheckbox/CustomCheckbox";

export const FoodTypes = [
    { key: "SALAD", label: "Salate" },
    { key: "SOUP", label: "Suppen" },
    { key: "COLD_STARTERS", label: "Kalte Vorspeisen" },
    { key: "MAIN_COURSE", label: "Hauptgerichte" },
    { key: "SIDE_DISHES", label: "Beilagen" },
    { key: "DESSERT", label: "Desserts" }
];

export const getAllFoods = async (foodType) => {
    try {
        const response = await axios.get(
            foodType
                ? `${BASEURL}/api/food/getAll?foodType=${foodType}&apiCompareToken=${API_COMPARE_TOKEN}`
                : `${BASEURL}/api/food/getAll?apiCompareToken=${API_COMPARE_TOKEN}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching foods:", error);
        return null;
    }
}

const Food = () => {
    const invitationId = localStorage.getItem("invitationId");
    const [foods, setFoods] = useState({});
    const [foodSelection, setFoodSelection] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);

    const toggleFoodSelection = (foodId) => {
        if (foodSelection.includes(foodId)) {
            setFoodSelection(foodSelection.filter(id => id !== foodId));
        } else {
            setFoodSelection([...foodSelection, foodId]);
        }
    };

    const getFoodSelection = async (invitationId) => {
        try {
            const response = await axios.get(`${BASEURL}/api/foodSelection/get?invitationId=${invitationId}&apiCompareToken=${API_COMPARE_TOKEN}`);
            return response.data;
        } catch (error) {
            console.error("Error getting food selection:", error);
            return null;
        }
    }

    const saveFoodSelection = async (invitationId) => {
        try {
            await axios.post(`${BASEURL}/api/foodSelection/set/${invitationId}?apiCompareToken=${API_COMPARE_TOKEN}`, foodSelection);
        } catch (error) {
            console.error("Error saving food selection:", error);
        }
    }

    const getAllFoodsForAllTypes = async () => {
        const foodsData = {};
        for (const foodType of FoodTypes) {
            try {
                foodsData[foodType.key] = await getAllFoods(foodType.key);
            } catch (error) {
                console.error(`Error fetching ${foodType.label} foods:`, error);
            }
        }
        setFoods(foodsData);
    }

    useEffect(() => {
        document.title = "Essen";
        getAllFoodsForAllTypes();
        getFoodSelection(invitationId).then(response => setFoodSelection(response));
    }, []);

    useEffect(() => {
        if(initialLoad) {
            setInitialLoad(false);
            return;
        }
        saveFoodSelection(invitationId);
    }, [foodSelection]);

    return (
        <div className={"foodComponent"}>
            <div className={"food-container"}>
                <Navigation/>
                <div className={"scroll-container"}>
                    <FoodManagement/>
                    <h1>Essen</h1>
                    <h3>Hier könnt ihr Eure Essenwünsche angeben</h3>
                    <div className={"food-types-container"}>
                        {FoodTypes.map((foodType) => (
                            <div key={foodType.key} className={"food-type"}>
                                <h2>{foodType.label}</h2>
                                <ul>
                                    {foods[foodType.key]?.map((food) => (
                                        <li key={food.foodId}>
                                            <CustomCheckbox
                                                inputId={`foodDescription_${food.foodId}`}
                                                isChecked={foodSelection.includes(food.foodId)}
                                                onChange={() => toggleFoodSelection(food.foodId)}
                                                text={food.description}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default authRequired(Food);