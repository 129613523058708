// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customCheckboxComponent .checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.5;
  margin-bottom: 5px;
}
.customCheckboxComponent .custom-checkbox {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f1f1f1;
}
.customCheckboxComponent input[type=checkbox]:checked ~ .custom-checkbox:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #debb00;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}
.customCheckboxComponent input[type=checkbox] {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/shared/customCheckbox/CustomCheckbox.scss"],"names":[],"mappings":"AAKE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAJJ;AAOE;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBApBiC;AAerC;AAQE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,qBAAA;EACA,yBAAA;EACA,wBAAA;AANJ;AASE;EACE,aAAA;AAPJ","sourcesContent":[".customCheckboxComponent {\n  $custom-checkbox-background-color: #f1f1f1;\n  $custom-checkbox-color: #debb00;\n  $custom-checkbox-line-width: 4px;\n\n  .checkbox-label {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    line-height: 1.5;\n    margin-bottom: 5px;\n  }\n\n  .custom-checkbox {\n    position: relative;\n    display: inline-block;\n    width: 25px;\n    height: 25px;\n    border-radius: 5px;\n    margin-left: 10px;\n    margin-right: 10px;\n    background-color: $custom-checkbox-background-color;\n  }\n\n  input[type='checkbox']:checked ~ .custom-checkbox:after {\n    content: '';\n    position: absolute;\n    left: 8px;\n    top: 3px;\n    width: 6px;\n    height: 12px;\n    border: solid $custom-checkbox-color;\n    border-width: 0 $custom-checkbox-line-width $custom-checkbox-line-width 0;\n    transform: rotate(45deg);\n  }\n\n  input[type=\"checkbox\"] {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
