import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.scss";

const PageNotFound = () => {
    return (
        <div className="pageNotFound">
            <h1 className="title">404 - Seite nicht gefunden</h1>
            <p className="message">Die von Ihnen angeforderte Seite existiert nicht.</p>
            <Link to="/" className="button">Zurück zur Startseite</Link>
        </div>
    );
}

export default PageNotFound;