import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import "./Navigation.scss";
import {adminCheck} from "../login/Login";
import LoadMediaSurvey from "../shared/loadMediaSurvey/LoadMediaSurvey";

const Navigation = () => {
    const invitationId = localStorage.getItem("invitationId");
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const [showLoadMediaSurvey, setShowLoadMediaSurvey] = useState(false);

    useEffect(() => {
        if(invitationId) {
            adminCheck(invitationId).then(result => {
                setIsAdmin(result);
            });
        }
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMediaTab = () => {
        if(location.pathname != "/media") {
            setShowLoadMediaSurvey(true);
            navigate(location.pathname);
        }
    }

    const handleMediaSurveyLoad = () => {
        setShowLoadMediaSurvey(false);
        navigate("/media");
    }

    const handleMediaSurveyCancel = () => {
        setShowLoadMediaSurvey(false);
    }

    const handleLogout = () => {
        localStorage.removeItem("invitationId");
        navigate("/login");
    }

    return (
        <div className={"navigationComponent"}>
            {showLoadMediaSurvey && (<LoadMediaSurvey loadAction={handleMediaSurveyLoad} cancelAction={handleMediaSurveyCancel}/>)}
            <div className={"nav-bar"}>
                <button className={"burger-menu"} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </button>
                <div className={menuOpen ? "menu-items menu-open" : "menu-items"}>
                    <div className={location.pathname === "/" ? "active" : ""}>
                        <Link to="/">Startseite</Link>
                    </div>
                    <div className={location.pathname === "/invitation" ? "active" : ""}>
                        <Link to="/invitation">Meine Einladung</Link>
                    </div>
                    <div className={location.pathname === "/media" ? "active" : ""}>
                        <Link to="/media">Hochzeitsmedien</Link>
                    </div>
                    <div className={location.pathname === "/food" ? "active" : ""}>
                        <Link to="/food">Essen</Link>
                    </div>
                    {isAdmin && (
                        <div className={location.pathname === "/answers" ? "active" : ""}>
                            <Link to="/answers">Antworten</Link>
                        </div>
                    )}
                </div>
                <button className={"logout-button"} onClick={handleLogout}>
                    Abmelden
                </button>
            </div>
        </div>
    );
}

export default Navigation;