import React, {Component, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {adminCheck} from "./Login";

const adminRequired = (Component, redirect) => {
    return (props) => {
        const navigate = useNavigate();
        const [isAdmin, setIsAdmin] = useState(false);

        useEffect(() => {
            const invitationId = localStorage.getItem("invitationId");
            adminCheck(invitationId).then(result => {
                setIsAdmin(result);
                if(!result && redirect) {
                    navigate("/login");
                }
            });
        }, [navigate]);

        return isAdmin ? <Component {...props}/> : null;
    }
}

export default adminRequired;