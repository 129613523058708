// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.loading-animation .spinnerDesc {
  margin-bottom: 10px;
  color: white;
}
.loading-animation .spinner {
  width: 70px;
  height: 70px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #debb00;
  animation: spin 1s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/shared/loadingAnimation/LoadingAnimation.scss"],"names":[],"mappings":"AAEA;EACI;IAAK,uBAAA;EACP;EAAE;IAAO,yBAAA;EAGT;AACF;AADA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AAGJ;AADI;EACI,mBAAA;EACA,YAAA;AAGR;AAAI;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,6BAAA;EACA,kCAAA;AAER","sourcesContent":["@import url(\"https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css\");\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n.loading-animation {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.8);\n\n    .spinnerDesc {\n        margin-bottom: 10px;\n        color: white;\n    }\n\n    .spinner {\n        width: 70px;\n        height: 70px;\n        border: 5px solid #f3f3f3;\n        border-radius: 50%;\n        border-top: 5px solid #debb00;\n        animation: spin 1s linear infinite;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
