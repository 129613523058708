import React, {useEffect, useState} from "react";
import "./FoodManagement.scss";
import authRequired from "../login/authRequired";
import adminRequired from "../login/adminRequired";
import axios from "axios";
import {FoodTypes, getAllFoods} from "./Food";
import {API_COMPARE_TOKEN, BASEURL} from "../App";
import Modal from "../shared/modal/Modal";

const FoodManagement = () => {
    const [isFoodManagementOpen, setIsFoodManagementOpen] = useState(false);
    const [foods, setFoods] = useState([]);
    const [newFoodType, setNewFoodType] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [foodToDelete, setFoodToDelete] = useState(null);
    const [foodSelectionCounts, setFoodSelectionCounts] = useState({});

    const handleDeleteFood = (food) => {
        setFoodToDelete(food);
        setShowConfirmation(true);
    };

    const confirmDeleteFood = () => {
        deleteFood(foodToDelete.foodId)
        setShowConfirmation(false);
    };

    const cancelDeleteFood = () => {
        setShowConfirmation(false);
        setFoodToDelete(null);
    };

    useEffect(() => {
        getAllFoods().then(foods => setFoods(foods));
    }, []);

    useEffect(() => {
        fetchFoodSelectionCounts(foods).then(counts => setFoodSelectionCounts(counts));
    }, [foods]);

    const toggleFoodManagement = () => {
        setIsFoodManagementOpen(!isFoodManagementOpen);
    };

    const fetchFoodSelectionCounts = async (foods) => {
        const counts = {};
        for (const food of foods) {
            counts[food.foodId] = await getFoodSelectionCount(food.foodId);
        }
        return counts;
    };

    const getFoodSelectionCount = async (foodId) => {
        try {
            const response = await axios.get(`${BASEURL}/api/foodSelection/getFoodSelectionCount?foodId=${foodId}&apiCompareToken=${API_COMPARE_TOKEN}`);
            return response.data;
        } catch (error) {
            console.error("Could not load count for food selection:", error);
            return -1;
        }
    }

    const addFood = async () => {
        try {
            const response = await axios.post(`${BASEURL}/api/food/add?foodType=${newFoodType}&description=${newDescription}&apiCompareToken=${API_COMPARE_TOKEN}`);
            setNewFoodType("");
            setNewDescription("");
            if(response.status === 200) {
                getAllFoods().then(foods => setFoods(foods));
            }
        } catch (error) {
            console.error("Error adding food:", error);
        }
    }

    const updateFood = async (foodId, foodType, description) => {
        try {
            await axios.put(`${BASEURL}/api/food/update?foodId=${foodId}&foodType=${foodType}&description=${description}&apiCompareToken=${API_COMPARE_TOKEN}`);
        } catch (error) {
            console.error("Error updating food:", error);
        }
    }

    const deleteFood = async (foodId) => {
        try {
            const response = await axios.delete(`${BASEURL}/api/food/delete?foodId=${foodId}&apiCompareToken=${API_COMPARE_TOKEN}`);
            if(response.status === 200) {
                setFoods(prevFoods => prevFoods.filter(food => food.foodId !== foodId));
            }
        } catch (error) {
            console.error("Error deleting food:", error);
        }
    }

    const getFoodTypeOptions = (withEmpty) => {
        const emptyOption = withEmpty ? <option value="">Typ auswählen</option> : null;
        return (
            <>
                ${emptyOption}
                ${FoodTypes.map(foodType => (
                    <option key={`${foodType.key}_${foodType.label}`} value={foodType.key}>{foodType.label}</option>
                ))}
            </>
        );
    }

    const saveChanges = () => {
        foods.forEach(food => {updateFood(food.foodId, food.foodType, food.description)});
    }

    const handleFoodInputChange = (e, foodId, field) => {
        const updatedFoods = foods.map((food) => {
            if(food.foodId === foodId) {
                return {
                    ...food,
                    [field]: e.target.value
                }
            }
            return food;
        });
        setFoods(updatedFoods);
    }

    return (
        <div className={"foodManagementComponent"}>
            <button className={"toggleFoodManagementButton"} onClick={toggleFoodManagement}>
                {isFoodManagementOpen ? "Schließen" : "Öffnen"} Food Management
            </button>
            {isFoodManagementOpen && (
                <div className="food-management-container">
                    <h1 className={"title"}>Essen-Management</h1>
                    <div className="add-food-section">
                        <h2>Neues Essen hinzufügen:</h2>
                        <select
                            value={newFoodType}
                            onChange={(e) => setNewFoodType(e.target.value)}
                        >
                            {getFoodTypeOptions(true)}
                        </select>
                        <input
                            type="text"
                            placeholder="Beschreibung"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                        />
                        <button onClick={addFood}>Hinzufügen</button>
                    </div>
                    <div className="list-foods-section">
                        <h2>Alle Essen auflisten:</h2>
                        <ul>
                            {foods.map((food) => (
                                <li key={food.foodId}>
                                    <select
                                        value={food.foodType}
                                        onChange={(e) =>
                                            handleFoodInputChange(e, food.foodId, "foodType")
                                        }
                                    >
                                        {getFoodTypeOptions(false)}
                                    </select>
                                    <input
                                        type="text"
                                        value={food.description}
                                        onChange={(e) =>
                                            handleFoodInputChange(e, food.foodId, "description")
                                        }
                                    />
                                    <span>Anzahl Auswahl: <strong>{foodSelectionCounts[food.foodId]}</strong></span>
                                    <button className={"deleteButton"} onClick={() => handleDeleteFood(food)}>
                                        Löschen
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div>
                            <button className={"saveButton"} onClick={saveChanges}>Änderungen speichern</button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <Modal
                    infoText={`Möchtest du ${foodToDelete.description} (${foodToDelete.foodType}) wirklich löschen?`}
                    yesText={"Ja, löschen"}
                    yesAction={confirmDeleteFood}
                    noText={"Abbrechen"}
                    noAction={cancelDeleteFood}
                />
            )}
        </div>
    );
}

export default authRequired(adminRequired(FoodManagement, false));