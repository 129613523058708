import React, {useEffect, useState} from "react";
import "./Invitation.scss";
import authRequired from "../login/authRequired";
import Navigation from "../navigation/Navigation";
import axios from "axios";
import {getAnswerTypeOptions} from "../answers/Answers";
import {API_COMPARE_TOKEN, BASEURL} from "../App";
import Modal from "../shared/modal/Modal";
import CustomCheckbox from "../shared/customCheckbox/CustomCheckbox";

export const getInvitation = async (invitationId) => {
    try {
        const response = await axios.get(`${BASEURL}/api/invitation/getInvitation?invitationId=${invitationId}&apiCompareToken=${API_COMPARE_TOKEN}`);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const getInvitationsByPartOf = async (invitationId) => {
    try {
        const limit = 10;
        const offset = 0;
        const response = await axios.get(`${BASEURL}/api/invitation/getAllInvitations?partOf=${invitationId}&limit=${limit}&offset=${offset}&apiCompareToken=${API_COMPARE_TOKEN}`);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const refreshInvitationCompanions = (invitationId, setCompanions, setCompanionsBuffer) => {
    getInvitationsByPartOf(invitationId).then(response => {
        setCompanions(response);
        setCompanionsBuffer(response);
    });
}

export const updateInvitation = async (invitationId, invitation) => {
    try {
        if(invitation.firstName.trim().length === 0 || invitation.familyName.trim().length === 0) {
            return null;
        }

        await axios.put(`${BASEURL}/api/invitation/updateInvitation/${invitationId}?firstName=${invitation.firstName}&familyName=${invitation.familyName}&child=${invitation.child}&answer=${invitation.answer}&partOf=${invitation.partOf}&apiCompareToken=${API_COMPARE_TOKEN}`);
    } catch (error) {
        return null;
    }
}

export const createNewCompanion = async (invitationId, setCompanions, setCompanionsBuffer) => {
    try {
        const response = await axios.post(`${BASEURL}/api/invitation/createInvitation?firstName&familyName&partOf=${invitationId}&apiCompareToken=${API_COMPARE_TOKEN}`);
        if(response.status === 200) {
            refreshInvitationCompanions(invitationId, setCompanions, setCompanionsBuffer);
        }
    } catch (error) {
        return null;
    }
}

export const deleteCompanion = async (invitationId, setCompanions, setCompanionsBuffer) => {
    try {
        const response = await axios.delete(`${BASEURL}/api/invitation/delete?invitationId=${invitationId}&apiCompareToken=${API_COMPARE_TOKEN}`);
        if(response.status === 200) {
            setCompanions(prevCompanions => prevCompanions.filter(companion => companion.invitationId !== invitationId));
            setCompanionsBuffer(prevCompanions => prevCompanions.filter(companion => companion.invitationId !== invitationId));
        }
    } catch (error) {
        return null;
    }
}

const Invitation = () => {
    const invitationId = localStorage.getItem("invitationId");
    const [mainInvitation, setMainInvitation] = useState({
        firstName: "",
        familyName: "",
        child: false,
        answer: "",
        partOf: ""
    });
    const [mainInvitationBuffer, setMainInvitationBuffer] = useState({ ...mainInvitation });
    const [mainInvitationTimeoutId, setMainInvitationTimeoutId] = useState(null);
    const [companions, setCompanions] = useState([]);
    const [companionsBuffer, setCompanionsBuffer] = useState([]);
    const [companionsTimeoutId, setCompanionsTimeoutId] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [companionToDelete, setCompanionToDelete] = useState(null);
    const [showAgeInfo, setShowAgeInfo] = useState(null);
    const ageInfoMessage = "Jünger als 7 Jahre";

    const handleAgeInfoHover = (invitationId) => {
        setShowAgeInfo(invitationId);
    };

    const handleAgeInfoClick = (invitationId) => {
        setShowAgeInfo(showAgeInfo === invitationId ? null : invitationId);
    };

    const handleInvitationChange = (field, value, bufferState, setBufferState, timeoutIdState, setTimeoutIdState) => {
        const updatedDraft = { ...bufferState, [field]: value };
        setBufferState(updatedDraft);

        if (timeoutIdState) {
            clearTimeout(timeoutIdState);
        }

        const newTimeoutId = setTimeout(() => {
            setMainInvitation(prevState => ({ ...prevState, ...updatedDraft }));
        }, 500);
        setTimeoutIdState(newTimeoutId);
    };

    const handleMainInvitationChange = (field, value) => {
        handleInvitationChange(field, value, mainInvitationBuffer, setMainInvitationBuffer, mainInvitationTimeoutId, setMainInvitationTimeoutId);
    };

    const handleCompanionInputChange = (e, index, field) => {
        const updatedCompanionsBuffer = companionsBuffer.map((companion, i) => {
            if (i === index) {
                const updatedValue = field === "child" ? e.target.checked : e.target.value;
                return { ...companion, [field]: updatedValue };
            }
            return companion;
        });

        setCompanionsBuffer(updatedCompanionsBuffer);

        if (companionsTimeoutId) {
            clearTimeout(companionsTimeoutId);
        }

        const newCompanionsTimeoutId = setTimeout(() => {
            setCompanions(updatedCompanionsBuffer);
        }, 500);

        setCompanionsTimeoutId(newCompanionsTimeoutId);
    };

    const handleDeleteCompanion = (companion) => {
        setCompanionToDelete(companion);
        setShowConfirmation(true);
    };

    const confirmDeleteCompanion = () => {
        deleteCompanion(companionToDelete.invitationId, setCompanions, setCompanionsBuffer);
        setShowConfirmation(false);
    };

    const cancelDeleteCompanion = () => {
        setShowConfirmation(false);
        setCompanionToDelete(null);
    };

    const saveChanges = () => {
        updateInvitation(invitationId, mainInvitation);
        companions.forEach(companion => updateInvitation(companion.invitationId, companion));
    }

    useEffect(() => {
        document.title = "Meine Einladung";

        getInvitation(invitationId).then(response => {
            setMainInvitation(response);
            setMainInvitationBuffer(response);
        });

        refreshInvitationCompanions(invitationId, setCompanions, setCompanionsBuffer);
    }, []);

    useEffect(() => {
        saveChanges();
    }, [mainInvitation, companions]);

    const getButtonsHtml = () => {
        return (
            <div className={"buttons"}>
                <button
                    className={"createNewCompanionButton"}
                    onClick={() => { saveChanges(); createNewCompanion(invitationId, setCompanions, setCompanionsBuffer) }}
                >
                    Begleitung hinzufügen
                </button>
            </div>
        )
    }

    return (
        <div className="invitationComponent">
            <div className={"invitation-container"}>
                <Navigation/>
                <div className={"scroll-container"}>
                    <div className={"invitationForm"}>
                        <h1>Meine Einladung</h1>
                        <div className={"inputField"}>
                            <label htmlFor={"firstName"}>Vorname:</label>
                            <input
                                id={"firstName"}
                                type={"text"}
                                value={mainInvitationBuffer.firstName}
                                placeholder={"Vorname"}
                                onChange={(e) => handleMainInvitationChange("firstName", e.target.value)}
                            />
                        </div>
                        <div className={"inputField"}>
                            <label htmlFor={"familyName"}>Familienname:</label>
                            <input
                                id={"familyName"}
                                type={"text"}
                                value={mainInvitationBuffer.familyName}
                                placeholder={"Familienname"}
                                onChange={(e) => handleMainInvitationChange("familyName", e.target.value)}
                            />
                        </div>
                        <div className={"inputField childInputField"}>
                            <CustomCheckbox
                                inputId={`child`}
                                isChecked={mainInvitationBuffer.child}
                                onChange={(e) => handleMainInvitationChange("child", e.target.checked)}
                                text={"Kind:"}
                                beforeCheckbox={true}
                            />
                            <span
                                className="info-icon"
                                onMouseEnter={() => handleAgeInfoHover(mainInvitation.invitationId)}
                                onMouseLeave={() => setShowAgeInfo(null)}
                                onClick={() => handleAgeInfoClick(mainInvitation.invitationId)}
                            />
                            {showAgeInfo === mainInvitation.invitationId && (
                                <div className="info-popup">{ageInfoMessage}</div>
                            )}
                        </div>
                        <div className={"inputField"}>
                            <label htmlFor={"answer"}>Antwort:</label>
                            <select
                                id={"answer"}
                                value={mainInvitationBuffer.answer}
                                onChange={(e) => handleMainInvitationChange("answer", e.target.value)}
                            >
                                {getAnswerTypeOptions()}
                            </select>
                        </div>
                    </div>
                    {companionsBuffer.length !== 0 && (
                        <div className={"companionsList"}>
                            <h2>Meine Begleitungen</h2>
                            {companionsBuffer.map((companion, index) => (
                                <div className={"companion"} key={companion.invitationId}>
                                    <div className={"inputField"}>
                                        <label htmlFor={`companionFirstName_${index}`}>Vorname:</label>
                                        <input
                                            id={`companionFirstName_${index}`}
                                            type="text"
                                            value={companion.firstName}
                                            placeholder={"Vorname"}
                                            onChange={(e) => handleCompanionInputChange(e, index, "firstName")}
                                        />
                                    </div>
                                    <div className={"inputField"}>
                                        <label htmlFor={`companionFamilyName_${index}`}>Familienname:</label>
                                        <input
                                            id={`companionFamilyName_${index}`}
                                            type="text"
                                            value={companion.familyName}
                                            placeholder={"Familienname"}
                                            onChange={(e) => handleCompanionInputChange(e, index, "familyName")}
                                        />
                                    </div>
                                    <div className={"inputField childInputField"}>
                                        <CustomCheckbox
                                            inputId={`companionChild_${index}`}
                                            isChecked={companion.child}
                                            onChange={(e) => handleCompanionInputChange(e, index, "child")}
                                            text={"Kind:"}
                                            beforeCheckbox={true}
                                        />
                                        <span
                                            className="info-icon"
                                            onMouseEnter={() => handleAgeInfoHover(companion.invitationId)}
                                            onMouseLeave={() => setShowAgeInfo(null)}
                                            onClick={() => handleAgeInfoClick(companion.invitationId)}
                                        />
                                        {showAgeInfo === companion.invitationId && (
                                            <div className="info-popup">{ageInfoMessage}</div>
                                        )}
                                    </div>
                                    <div className={"inputField"}>
                                        <label htmlFor={`companionAnswer_${index}`}>Antwort:</label>
                                        <select
                                            id={`companionAnswer_${index}`}
                                            value={companion.answer}
                                            onChange={(e) => handleCompanionInputChange(e, index, "answer")}
                                        >
                                            {getAnswerTypeOptions()}
                                        </select>
                                    </div>
                                    <button
                                        className={"deleteButton"}
                                        onClick={() => handleDeleteCompanion(companion)}
                                    >
                                        Begleitung löschen
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    {getButtonsHtml()}
                    {showConfirmation && (
                        <Modal
                            infoText={`Möchtest du ${companionToDelete.firstName} ${companionToDelete.familyName} wirklich löschen?`}
                            yesText={"Ja, löschen"}
                            yesAction={confirmDeleteCompanion}
                            noText={"Abbrechen"}
                            noAction={cancelDeleteCompanion}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default authRequired(Invitation);