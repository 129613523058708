import React from "react";
import "./CustomCheckbox.scss";

const CustomCheckbox = (props: Props) => {
    return (
        <div className={"customCheckboxComponent"}>
            <label className={`checkbox-label`} htmlFor={props.inputId}>
                <input
                    id={props.inputId}
                    type={"checkbox"}
                    checked={props.isChecked}
                    onChange={props.onChange}
                />
                {props.beforeCheckbox && (
                    <span className={"checkbox-text"}>{props.text}</span>
                )}
                <span className={"custom-checkbox"}></span>
                {!props.beforeCheckbox && (
                    <span className={"checkbox-text"}>{props.text}</span>
                )}
            </label>
        </div>
    );
}

export default CustomCheckbox;