import React, {useEffect, useRef, useState} from "react";
import "./Media.scss";
import authRequired from "../login/authRequired";
import Navigation from "../navigation/Navigation";
import axios from "axios";
import LoadingAnimation from "../shared/loadingAnimation/LoadingAnimation";
import {API_COMPARE_TOKEN, BASEURL} from "../App";
import CustomCheckbox from "../shared/customCheckbox/CustomCheckbox";
import Modal from "../shared/modal/Modal";
import {adminCheck} from "../login/Login";

const TABS = [
    { key: "PHOTOGRAPHER", label: "Fotograf" },
    { key: "PHOTO_BOX", label: "Foto-Box" },
    { key: "GUESTS_UPLOADS", label: "Gästeuploads" }
];

export const getMediaByCategory = async (category) => {
    try {
        const response = await axios.get(`${BASEURL}/api/media/info?mediaCategory=${category}&includeMediaData=true&apiCompareToken=${API_COMPARE_TOKEN}`);
        return response.data.media;
    } catch (error) {
        console.error(`Error fetching media for category ${category}:`, error);
        return [];
    }
}

const Media = () => {
    const invitationId = localStorage.getItem("invitationId");
    const [activeTab, setActiveTab] = useState("PHOTOGRAPHER");
    const [isAdmin, setIsAdmin] = useState(false);
    const [dropboxLinkReady, setDropboxLinkReady] = useState(false);
    const [dropboxLink, setDropboxLink] = useState("");
    const [dropboxLinkDesc, setDropboxLinkDesc] = useState("");

    const imageDropboxLinkFunction = () => {
        if(activeTab === "PHOTOGRAPHER") {
            setDropboxLinkReady(true);
            setDropboxLink("https://www.dropbox.com/scl/fo/tyuvqectdezv7qp2pi0el/AFHmTDg44JGvKRDJGoyljHI?rlkey=6n6j92cs448rdozxvii4aedir&dl=0");
            setDropboxLinkDesc("Hier klicken, um zu den die Fotos & Videos des Fotografen & Videografen zu gelangen");
        } else if(activeTab === "PHOTO_BOX") {
            setDropboxLinkReady(true);
            setDropboxLink("https://www.dropbox.com/scl/fo/sbqzg4n5zybq2jadoqgj0/AEsrgYlenPoQW9k8m1d81jc?rlkey=xf0mk91ro4vyzevukc9z3ojjb&dl=0");
            setDropboxLinkDesc("Hier klicken, um zu den Fotos der Foto-Box zu gelangen");
        } else if(activeTab === "GUESTS_UPLOADS") {
            setDropboxLinkReady(true);
            setDropboxLink("https://www.dropbox.com/scl/fo/v71imlzn2qzy85rylhpls/AB8jtCOCoQMXLlAehD62whM?rlkey=4atr9bg606k2k183bx75mcz3o&st=trnpievj&dl=0");
            setDropboxLinkDesc("Hier klicken, um zu den Gästeuploads zu gelangen. Schickt uns gerne eure Fotos & Videos zu, wir laden diese dann hoch.");
        }
    }

    const handleTabClick = async (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        document.title = "Hochzeitsbilder";
        if(invitationId) {
            adminCheck(invitationId).then(result => {
                setIsAdmin(result);
            });
        }
    }, []);

    useEffect(() => {
        imageDropboxLinkFunction();
    }, [activeTab]);

    return (
        <div className={"mediaComponent"}>
            <div className={"media-container"}>
                <Navigation/>
                <h1>Hochzeitsmedien</h1>
                <div className="tabs">
                    <div className="tabs-inner">
                        {TABS.map((tab) => (
                            <button
                                key={tab.key}
                                className={activeTab === tab.key ? "active" : ""}
                                onClick={() => handleTabClick(tab.key)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
                <div className={"scroll-container"}>
                    <div>
                        <div className={"mediaWrapper"}>
                            <div className={"imagePlaceholder"}>
                                {
                                    dropboxLinkReady && (
                                        <a href={dropboxLink} target={"_blank"}>{dropboxLinkDesc}</a>
                                    )
                                }
                                {
                                    !dropboxLinkReady && (
                                        <h3>Sobald wir die Fotos & Videos haben, stellen wir hier den Dropbox-Link zur Verfügung!</h3>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default authRequired(Media);