import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./login/Login";
import Media from "./media/Media";
import Invitation from "./invitation/Invitation";
import Home from "./home/Home";
import PageNotFound from "./pageNotFound/PageNotFound";
import Food from "./food/Food";
import Answers from "./answers/Answers";

const localDebugging = false;
const secure = true;
const protocol = secure ? "https" : "http";
const prodPort = secure ? "9443" : "9080";
export const BASEURL = localDebugging ? `${protocol}://localhost:9090` : `${protocol}://dennisundkatja-hochzeit.de:${prodPort}`;

export const API_COMPARE_TOKEN = "pzjF2YwPVx_@93nz.LnM";

export const SUMMER_TIME = true;

const App = () => {
    useEffect(() => {
        document.title = "Dennis & Katja";
    }, []);

    return (
        <Router>
            <Routes>
                <Route path={"/login"} Component={Login}/>
                <Route path={"/media"} Component={Media}/>
                <Route path={"/invitation"} Component={Invitation}/>
                <Route path={"/food"} Component={Food}/>
                <Route path={"/answers"} Component={Answers}/>
                <Route path={"/"} Component={Home}/>
                <Route path={"*"} Component={PageNotFound}/>
            </Routes>
        </Router>
    )
}

export default App;