import React from "react";
import "./LoadingAnimation.scss";

const LoadingAnimation = (props: Props) => {
    return (
        <div className={"loading-animation"}>
            <div className={"spinnerDesc"}>{props.desc}</div>
            <div className={"spinner"}></div>
        </div>
    );
}

export default LoadingAnimation;