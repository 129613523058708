// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customCountdown .rendererContainer {
  display: flex;
  flex-direction: column;
}
.customCountdown .rendererContainer span {
  color: #e3dcbe;
  font-size: 6rem;
  font-family: "Mali", cursive;
  font-weight: 200;
  font-style: normal;
}
@media only screen and (max-width: 1300px) {
  .customCountdown .rendererContainer span {
    font-size: 5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .customCountdown .rendererContainer span {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 1100px) {
  .customCountdown .rendererContainer span {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 1000px) {
  .customCountdown .rendererContainer span {
    font-size: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/customCountdown/CustomCountdown.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,sBAAA;AAHJ;AAKI;EACE,cARmB;EASnB,eARkB;EASlB,4BAAA;EACA,gBAAA;EACA,kBAAA;AAHN;AAKM;EAPF;IAQI,eAAA;EAFN;AACF;AAGM;EAVF;IAWI,eAAA;EAAN;AACF;AACM;EAbF;IAcI,eAAA;EAEN;AACF;AADM;EAhBF;IAiBI,eAAA;EAIN;AACF","sourcesContent":[".customCountdown {\n  $countdown-text-color: #e3dcbe;\n  $countdown-font-size: 6rem;\n\n  .rendererContainer {\n    display: flex;\n    flex-direction: column;\n\n    span {\n      color: $countdown-text-color;\n      font-size: $countdown-font-size;\n      font-family: \"Mali\", cursive;\n      font-weight: 200;\n      font-style: normal;\n\n      @media only screen and (max-width: 1300px) {\n        font-size: 5rem;\n      }\n      @media only screen and (max-width: 1200px) {\n        font-size: 4rem;\n      }\n      @media only screen and (max-width: 1100px) {\n        font-size: 3rem;\n      }\n      @media only screen and (max-width: 1000px) {\n        font-size: 2rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
