// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.pageNotFound .title {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.pageNotFound .message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.pageNotFound .button {
  display: inline-block;
  padding: 0.8rem 2rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.pageNotFound .button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/pageNotFound/PageNotFound.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;AACJ;AACI;EACI,eAAA;EACA,mBAAA;AACR;AAEI;EACI,iBAAA;EACA,mBAAA;AAAR;AAGI;EACI,qBAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,sCAAA;AADR;AAGQ;EACI,yBAAA;AADZ","sourcesContent":[".pageNotFound {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n\n    .title {\n        font-size: 2rem;\n        margin-bottom: 1rem;\n    }\n\n    .message {\n        font-size: 1.2rem;\n        margin-bottom: 2rem;\n    }\n\n    .button {\n        display: inline-block;\n        padding: 0.8rem 2rem;\n        background-color: #007bff;\n        color: #fff;\n        text-decoration: none;\n        font-size: 1rem;\n        border-radius: 5px;\n        transition: background-color 0.3s ease;\n\n        &:hover {\n            background-color: #0056b3;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
