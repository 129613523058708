import React from "react";
import "./CustomCountdown.scss";
import Countdown from "react-countdown";

const CustomCountdown = (props: Props) => {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return (
                <div className={"rendererContainer"}>
                    <span>Danke für den schönen Tag!</span>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className={"rendererContainer"}>
                    <span>{days} Tage</span>
                    <span>{hours} Stunden {minutes} Minuten</span>
                    <span>{seconds} Sekunden</span>
                </div>
            );
        }
    };

    return (
        <div className={"customCountdown"}>
            <Countdown date={props.date} renderer={renderer}/>
        </div>
    );
}

export default CustomCountdown;